import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { graphql } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import ImageProvider from '@core/providers/image/image-provider';

import Hero from '@shared/hero/hero';
import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';

import './styles.scss';
import Presenters from './components/presenters';

const { event: crumbs } = require('@config/breadcrumbs');

type EventProps = {
  language: string;
  data: {
    event: Graphql;
  };
};

export default function EventPage({
  data,
  language
}: EventProps): React.ReactElement {
  const { t } = useTranslation();
  const { event } = data;
  const crumb = { label: event.title, link: '#' };
  const splitDataEvent = event.date ? event.date.toString().split(' ') : [];
  const contentStyle = event?.contentStyle || 'with-sidebar';
  const isFullWidth = contentStyle === 'full-width';

  const dateData = {
    startYear: splitDataEvent[0] ?? '',
    startMon: splitDataEvent[1] ?? '',
    startDay: splitDataEvent[2] ?? '',
    startDayName: splitDataEvent[3] ?? '',
    startHour: splitDataEvent[4] ?? '',
    startFullDate: `${splitDataEvent[0]}-${splitDataEvent[1]}-${splitDataEvent[2]}`
  };

  const splitEndDataEvent = event?.endDate?.toString().split(' ') ?? [];

  const dateEndData = {
    endHour: splitEndDataEvent[4] ?? '',
    endFullDate: `${splitDataEvent[0]}-${splitDataEvent[1]}-${splitDataEvent[2]}`
  };

  const paragraphs = event?.relationships?.paragraphs?.map((node) =>
    ParagraphComponent(
      { ...node, path: event.path },
      language,
      event?.date?.toString()
    )
  );

  const imgBackgroundCard = require('@assets/images/bg-backcard-clients.png');
  const styleBackgroundCard = {
    backgroundImage: `url(${imgBackgroundCard.default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  };

  const endDate = () => {
    if (
      dateEndData.endFullDate === dateData.startFullDate &&
      dateEndData.endHour
    ) {
      return ` ${t('to')} ${dateEndData.endHour}`;
    }

    return '';
  };

  const { featureImage } = event?.relationships || {};

  return (
    <>
      {event?.relationships?.hero && (
        <Hero node={{ ...event.relationships.hero, title: event?.title }} />
      )}
      <Container className="container-event mt-4">
        <Row>
          <Breadcrumb crumbs={crumbs.concat(crumb)} />
          <Col className="mr-5" md={isFullWidth ? 12 : 7}>
            {event?.body?.processed && (
              <div className="text-black wysiwyg mt-3 mb-3">
                <DomPurify text={event.body.processed} />
                <ImageProvider
                  fileName={featureImage?.name}
                  alt={featureImage?.attributes?.alt}
                  title={featureImage?.attributes?.title}
                />
              </div>
            )}
          </Col>
          {!isFullWidth && (
            <Col md={4}>
              <div style={styleBackgroundCard} className="date-event w-100">
                <div className="bg-date-event w-100 d-flex m-2">
                  <div className="month-event">
                    <h5 className="text-light">
                      {t(`${dateData.startDayName}`)}
                    </h5>
                    <span className="text-light">
                      {t(`${dateData.startMon}`)}
                    </span>
                  </div>
                  <div className="day-event display-1">{dateData.startDay}</div>
                </div>
                <div className="w-100 text-light mb-3 p-2">
                  <span>
                    <b>{`${t('Time')}: `}</b>
                    {dateData.startHour}
                    {endDate()}
                  </span>
                  <br />
                  <span>
                    <b>{`${t('Registration')}: `}</b>
                    {event.status ? t('Open') : t('Closed')}
                  </span>
                </div>
              </div>

              <Presenters presenters={event?.relationships?.presenters || []} />
            </Col>
          )}
        </Row>
      </Container>
      {paragraphs && paragraphs.length > 0 && (
        <div className="page-components-container">{paragraphs}</div>
      )}
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    event: nodeEvent(id: { eq: $id }) {
      id
      title
      date: field_event_date(formatString: "yyyy MMMM DD dddd hh:mm")
      endDate: field_event_end_date(formatString: "yyyy MMMM DD dddd hh:mm")
      featured: field_featured
      status: field_event_status
      contentStyle: field_content_style
      body {
        processed
        summary
      }
      address: field_event_address {
        addressLine1: address_line1
      }
      path {
        alias
        langcode
      }
      relationships {
        featureImage: field_featured_image {
          name

          id
          attributes: field_media_image {
            alt
            title
          }
        }
        type: field_event_type {
          name
        }
        hero: field_hero {
          ...HeroParagraph
        }
        presenters: field_ref_presenter {
          id: drupal_internal__uid
          firstname: field_first_name
          lastname: field_last_name
          role: field_role
          relationships {
            picture: user_picture {
              name: filename
            }
          }
        }
        paragraphs: field_content_main {
          type: __typename
          ...CtaParagraph
          ...HeroParagraph
          ...HeroSliderParagraph
          ...BannerParagraph
          ...BannerTalentParagraph
          ...BannerAdvParagraph
          ...QuoteSliderParagraph
          ...QuoteParagraph
          ...IntroTextParagraph
          ...IntroBlockParagraph
          ...TimelineParagraph
          ...CardsParagraph
          ...BoxesParagraph
          ...GridParagraph
          ...ClientsParagraph
          ...TechnologiesParagraph
          ...TabsParagraph
          ...TableParagraph
          ...WysiwygParagraph
          ...ViewParagraph
          ...FeaturedContentParagraph
          ...BannerFormParagraph
          ...BannerTalentApplyParagraph
          ...EmergencyModalParagraph
          ...BannerVideoParagraph
          ...WebFormParagraph
          ...CollageParagraph
          ...FabParagraph
          ...ImageSliderParagraph
          ...OfficesParagraph
        }
      }
    }
  }
`;
